"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogLevel = exports.BugfenderClass = exports.Bugfender = void 0;
const common_1 = require("@bugfender/common");
Object.defineProperty(exports, "LogLevel", { enumerable: true, get: function () { return common_1.LogLevel; } });
const bugfender_1 = require("./bugfender");
Object.defineProperty(exports, "BugfenderClass", { enumerable: true, get: function () { return bugfender_1.Bugfender; } });
const sdk_provider_1 = require("./sdk.provider");
const api_feature_detection_1 = require("./feature-detection/api-feature-detection");
const operation_modes_1 = require("./feature-detection/operation-modes");
const apiFeatures = new api_feature_detection_1.APIFeatureDetection(window.indexedDB, window.localStorage, window.sessionStorage, window.BroadcastChannel, window.Worker);
const operationModes = new operation_modes_1.OperationModes(apiFeatures);
const provider = new sdk_provider_1.SDKProvider(apiFeatures, operationModes, SDK_USER_AGENT, VERSION, window);
const Bugfender = new bugfender_1.Bugfender(apiFeatures, provider.getCommandsQueue(), operationModes, provider);
exports.Bugfender = Bugfender;
