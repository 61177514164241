"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterUIEventsHandler = void 0;
const utils_1 = require("../utils");
const register_events_handler_base_1 = require("./register-events-handler.base");
class RegisterUIEventsHandler extends register_events_handler_base_1.RegisterEventsHandlerBase {
    constructor(commands, window) {
        super(window);
        this.commands = commands;
        this.window = window;
    }
    init() {
        if (!this.window.document.addEventListener) {
            return;
        }
        const options = this.supportsPassiveEvents() ?
            { passive: true, capture: true } :
            true;
        document.addEventListener('click', (e) => {
            if (e.target instanceof Element) {
                this.commands.addLogEntry(this.getLog(e.target, 'click'));
            }
        }, options);
        document.addEventListener('focusin', (e) => {
            if (e.target instanceof Element) {
                this.commands.addLogEntry(this.getLog(e.target, 'focus'));
            }
        }, options);
        document.addEventListener('focusout', (e) => {
            if (e.target instanceof Element) {
                this.commands.addLogEntry(this.getLog(e.target, 'blur'));
            }
        }, options);
        this.window.addEventListener('submit', (e) => {
            this.commands.addLogEntry(this.getLog(e.target, 'submit'));
        }, options);
        const debounceByEl = new utils_1.DebounceByElement(400);
        const ignoreList = [
            'Tab', 'Meta', 'MetaLeft', 'MetaRight', 'Alt', 'AltLeft', 'AltRight',
            'Control', 'ControlLeft', 'ControlRight', 'Shift', 'ShiftLeft', 'ShiftRight',
            'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown',
            'PageDown', 'PageUp', 'Home', 'End', 'CapsLock',
        ];
        this.window.addEventListener('keyup', (e) => {
            if (!(e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) ||
                !e.composedPath ||
                ignoreList.includes(e.code)) {
                return;
            }
            const target = e.target;
            const path = e.composedPath();
            debounceByEl.run(target, () => {
                this.sendKeyupLog(target, path);
            });
        }, options);
    }
    sendKeyupLog(target, path) {
        var _a;
        const isPasswordEl = ((_a = target.getAttribute('type')) !== null && _a !== void 0 ? _a : '').toLowerCase() === 'password';
        const shouldSkipKeyLogging = path
            .filter((el) => el instanceof HTMLElement)
            .some((el) => 'bfIgnoreKeypress' in el.dataset);
        const shouldOmitText = isPasswordEl || shouldSkipKeyLogging;
        this.commands.addLogEntry({
            tag: 'js_event',
            url: this.getURL(),
            text: JSON.stringify(Object.assign(Object.assign({}, this.getEventData(target, 'keyup')), { text: shouldOmitText ? undefined : target.value }))
        });
    }
    getLog(target, eventName) {
        return {
            tag: 'js_event',
            text: JSON.stringify(this.getEventData(target, eventName)),
            url: this.getURL(),
        };
    }
    getPathTo(element) {
        if (element.id !== '') {
            return 'id("' + element.id + '")';
        }
        if (element === document.body) {
            return element.tagName;
        }
        if (element.tagName === 'HTML') {
            return element.tagName;
        }
        let ix = 0;
        const siblings = element.parentNode.childNodes;
        for (let i = 0; i < siblings.length; i++) {
            const sibling = siblings[i];
            if (sibling === element) {
                return this.getPathTo(element.parentNode) + '/' + element.tagName + '[' + (ix + 1) + ']';
            }
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
                ix++;
            }
        }
    }
    getEventData(target, eventName) {
        if (target instanceof Element) {
            const text = (target.childElementCount <= 1) ? target.textContent : undefined;
            const form = (target.tagName === 'FORM') ? target : null;
            return {
                event: eventName,
                url: (this.window) ? this.window.location.href : '',
                xpath: this.getPathTo(target),
                tag: target.tagName,
                id: target.id,
                text: text,
                action: (form && form.action) ? form.action : '',
            };
        }
        return null;
    }
}
exports.RegisterUIEventsHandler = RegisterUIEventsHandler;
