"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriterHub = void 0;
const common_1 = require("@bugfender/common");
const storage_writer_sanitizer_1 = require("./storage-writer.sanitizer");
const commands_1 = require("./commands");
const issue_1 = require("./types/issue");
class StorageWriterHub {
    constructor(channel, device, handler, options, printToConsole, session, storageGuard, uuidFactory, window) {
        this.channel = channel;
        this.device = device;
        this.handler = handler;
        this.options = options;
        this.printToConsole = printToConsole;
        this.session = session;
        this.storageGuard = storageGuard;
        this.uuidFactory = uuidFactory;
        this.window = window;
        this.sanitizer = new storage_writer_sanitizer_1.StorageWriterSanitizer();
    }
    init(options) {
        const sessionUUID = this.session.getUUID();
        this.options = options;
        this.postMessage({
            type: 'init',
            data: { options, sessionUUID },
        }, false);
    }
    postMessage(message, checkStorageGuard = true) {
        if (checkStorageGuard && !this.storageGuard.canWrite()) {
            return;
        }
        try {
            this.handler.postMessage(this.sanitizer.sanitize(message));
        }
        catch (err) {
        }
    }
    addDeviceKey(cmd) {
        if (typeof cmd.key !== 'string') {
            throw new Error(`The device-key 'key' must be a string.`);
        }
        this.device.setDeviceKey(cmd.key, cmd.value);
        this.postMessage({
            type: 'device-key',
            data: {
                key: cmd.key,
                value: cmd.value,
                callInfo: cmd.callInfo,
                time: cmd.time,
            },
        });
        if (cmd.value === null) {
            this.printToConsole.info(`Device key "${cmd.key}" removed`);
        }
        else {
            this.printToConsole.info(`Device key "${cmd.key}" set to "${cmd.value}"`);
        }
    }
    addLogEntry(cmd, printLog = true) {
        var _a;
        cmd.log.url = ((_a = cmd.log.url) !== null && _a !== void 0 ? _a : (this.window)) ? this.window.location.href : '';
        const log = this.mapToLogEntryText(cmd.log);
        this.postMessage({
            type: 'log-entry',
            data: {
                log,
                callInfo: cmd.callInfo,
                time: cmd.time,
            },
        });
        if (printLog) {
            this.printToConsole.printLog(log);
        }
    }
    mapToLogEntryText(log) {
        if (log.text) {
            log.text = (0, common_1.formatLogEntryText)(log.text);
        }
        return log;
    }
    addLogMessage(cmd) {
        const log = {};
        const [, ...textParams] = cmd.parameters;
        let [textFormat] = cmd.parameters;
        let text;
        try {
            textFormat = (0, common_1.mapToString)(textFormat);
        }
        catch (_a) {
            textFormat = (0, common_1.format)([textFormat]);
        }
        if (textParams.length > 0) {
            text = (0, common_1.format)([textFormat, ...textParams]);
        }
        else {
            text = textFormat;
        }
        log.level = cmd.level;
        log.text = text;
        log.url = (this.window) ? this.window.location.href : null;
        this.addLogEntry({
            type: commands_1.CommandType.AddLogEntry,
            log,
            callInfo: cmd.callInfo,
            time: cmd.time,
        }, false);
        switch (cmd.level) {
            case common_1.LogLevel.Debug:
                this.printToConsole.log(...cmd.parameters);
                break;
            case common_1.LogLevel.Warning:
                this.printToConsole.warn(...cmd.parameters);
                break;
            case common_1.LogLevel.Error:
                this.printToConsole.error(...cmd.parameters);
                break;
            case common_1.LogLevel.Trace:
                this.printToConsole.trace(...cmd.parameters);
                break;
            case common_1.LogLevel.Info:
                this.printToConsole.info(...cmd.parameters);
                break;
            case common_1.LogLevel.Fatal:
                this.printToConsole.error(...cmd.parameters);
                break;
        }
    }
    addIssue(cmd) {
        if (typeof cmd.title !== 'string') {
            throw new Error(`The crash/issue/feedback 'title' must be a string.`);
        }
        this.postMessage({
            type: 'issue',
            data: {
                type: cmd.issueType,
                title: cmd.title,
                uuid: cmd.uuid,
                text: cmd.text,
                callInfo: cmd.callInfo,
                time: cmd.time,
            },
        });
        this.forceSendOnce();
        cmd.url.resolve(`${this.options.baseURL}/intent/${this.options.appID}/issue/${cmd.uuid}`);
        switch (cmd.issueType) {
            case issue_1.IssueType.Issue:
                this.printToConsole.warn(`Issue: ${cmd.title}.\n${cmd.text}`);
                break;
            case issue_1.IssueType.Crash:
                this.printToConsole.error(`Crash: ${cmd.title}.\n${cmd.text}`);
                break;
            case issue_1.IssueType.Feedback:
                this.printToConsole.info(`User Feedback: ${cmd.title}.\n${cmd.text}`);
                break;
        }
    }
    forceSendOnce() {
        this.channel.notifyForceSend();
    }
}
exports.StorageWriterHub = StorageWriterHub;
