"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandsQueue = void 0;
const commands_1 = require("./commands");
const utils_1 = require("./utils");
class CommandsQueue {
    constructor(callInfoFactory, timer, uuidFactory) {
        this.callInfoFactory = callInfoFactory;
        this.timer = timer;
        this.uuidFactory = uuidFactory;
        this.isInitialized = false;
        this.queue = [];
    }
    init(storage) {
        this.storage = storage;
        this.queue.forEach(cmd => {
            this.handleCommand(cmd);
        });
        this.isInitialized = true;
    }
    handleCommand(cmd) {
        switch (cmd.type) {
            case commands_1.CommandType.AddIssue:
                this.storage.addIssue(cmd);
                break;
            case commands_1.CommandType.AddLogEntry:
                this.storage.addLogEntry(cmd);
                break;
            case commands_1.CommandType.AddLogMessage:
                this.storage.addLogMessage(cmd);
                break;
            case commands_1.CommandType.ForceSendOnce:
                this.storage.forceSendOnce();
                break;
            case commands_1.CommandType.SetDeviceKey:
                this.storage.addDeviceKey(cmd);
                break;
            default:
                throw new utils_1.UnreachableCaseError(cmd);
        }
    }
    add(cmd) {
        if (this.isInitialized) {
            this.handleCommand(cmd);
        }
        else {
            this.queue.push(cmd);
        }
    }
    addIssue(issueType, title, text) {
        const url = (0, utils_1.createDeferred)();
        this.add({
            type: commands_1.CommandType.AddIssue,
            issueType,
            title,
            text,
            uuid: this.uuidFactory.create(),
            callInfo: this.callInfoFactory.create(),
            url,
            time: this.timer.getTime(),
        });
        return url.promise;
    }
    addLogEntry(log) {
        this.add({
            type: commands_1.CommandType.AddLogEntry,
            log,
            callInfo: this.callInfoFactory.create(),
            time: this.timer.getTime(),
        });
    }
    addLogMessage(level, parameters) {
        this.add({
            type: commands_1.CommandType.AddLogMessage,
            level,
            parameters,
            callInfo: this.callInfoFactory.create(),
            time: this.timer.getTime(),
        });
    }
    forceSendOnce() {
        this.add({
            type: commands_1.CommandType.ForceSendOnce
        });
    }
    setDeviceKey(key, value) {
        this.add({
            type: commands_1.CommandType.SetDeviceKey,
            key,
            value,
            callInfo: this.callInfoFactory.create(),
            time: this.timer.getTime(),
        });
    }
}
exports.CommandsQueue = CommandsQueue;
