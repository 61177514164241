"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBrowserEventsHandler = void 0;
const register_events_handler_base_1 = require("./register-events-handler.base");
class RegisterBrowserEventsHandler extends register_events_handler_base_1.RegisterEventsHandlerBase {
    constructor(commands, window) {
        super(window);
        this.commands = commands;
        this.window = window;
    }
    init() {
        if (!this.window.document.addEventListener) {
            return;
        }
        const options = this.supportsPassiveEvents() ?
            { passive: true, capture: true } :
            true;
        this.window.addEventListener('load', () => {
            this.commands.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'PageLoaded',
                    performance: this.window.performance
                }),
                url: this.getURL(),
            });
        }, options);
        this.window.addEventListener('DOMContentLoaded', () => {
            this.commands.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'DOMContentLoaded',
                }),
                url: this.getURL(),
            });
        }, options);
        this.window.addEventListener('popstate', (e) => {
            this.commands.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'popstate',
                    location: this.window.location.href,
                    state: JSON.stringify(e.state),
                }),
                url: this.getURL(),
            });
        }, options);
        this.window.addEventListener('hashchange', () => {
            this.commands.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'hashchange',
                    hash: this.window.location.hash,
                }),
                url: this.getURL(),
            });
        }, options);
        const historyOverrideFnFactory = (originalFn) => {
            const self = this;
            return function (...args) {
                const url = args.length > 2 ? args[2] : undefined;
                if (url) {
                    self.commands.addLogEntry({
                        tag: 'js_event',
                        text: JSON.stringify({
                            event: 'pushstate',
                            to: String(url),
                            state: args.length > 1 ? JSON.stringify(args[1]) : null
                        }),
                        url: self.getURL(),
                    });
                }
                return originalFn.apply(this, args);
            };
        };
        this.override(this.window.history, 'pushState', historyOverrideFnFactory);
        this.override(this.window.history, 'replaceState', historyOverrideFnFactory);
    }
    override(object, method, overrideFactory) {
        if (!(method in object)) {
            return;
        }
        const original = object[method];
        const wrapped = overrideFactory(original);
        object[method] = wrapped;
    }
}
exports.RegisterBrowserEventsHandler = RegisterBrowserEventsHandler;
