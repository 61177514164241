"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebounceByElement = exports.UnreachableCaseError = exports.CacheDecoratorFactory = exports.createDeferred = void 0;
function createDeferred() {
    const deferred = {
        isFulfilled: false,
        promise: undefined,
        resolve: undefined,
        reject: undefined,
    };
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = (value) => {
            deferred.isFulfilled = true;
            resolve(value);
        };
        deferred.reject = (reason) => {
            deferred.isFulfilled = true;
            reject(reason);
        };
    });
    return deferred;
}
exports.createDeferred = createDeferred;
function CacheDecoratorFactory(cache) {
    return function CacheDecorator() {
        return function (_, propertyKey, descriptor) {
            const method = descriptor.value;
            descriptor.value = function () {
                if (!cache.has(propertyKey)) {
                    cache.set(propertyKey, method.apply(this));
                }
                return cache.get(propertyKey);
            };
        };
    };
}
exports.CacheDecoratorFactory = CacheDecoratorFactory;
class UnreachableCaseError extends Error {
    constructor(val) {
        super(`Unreachable case: ${JSON.stringify(val)}`);
    }
}
exports.UnreachableCaseError = UnreachableCaseError;
class DebounceByElement {
    constructor(wait) {
        this.wait = wait;
        this.timers = new Map();
    }
    run(el, cb) {
        if (this.timers.has(el)) {
            clearTimeout(this.timers.get(el));
        }
        this.timers.set(el, setTimeout(() => {
            cb();
            this.timers.delete(el);
        }, this.wait));
    }
}
exports.DebounceByElement = DebounceByElement;
